import React from 'react';

import API from '../modules/API';
import Utils from '../modules/Utils';
import State from '../modules/State';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dashboard from "./Dasboard";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://risprojecten.nl">
                risprojecten.nl
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



const api = new API(this);

export default function SignIn() {
    const [authenticated, setAuthenticated] = React.useState(api.isAuthenticated());
    // const [user, setUser] = React.useState();
    State.addCallable('authenticated', setAuthenticated);
    const classes = useStyles();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        main: '#a36f14',
                    },
                    secondary: {
                        main: '#ffab00',
                    },
                },
                typography: {
                    fontSize: 14
                }
            }),
        [prefersDarkMode],
    );
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = Utils.formToObject(event.target);
        if (api.authenticate(data.email, data.password)) {
            api.user();
            // setUser(api.user());
            setAuthenticated(true);
        }
    }
    if (authenticated) {
        return (
            <Dashboard/>
        );
    }
    return (
        <Container component="main" maxWidth="xs">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <img src="/img/logo-light.png" width="400px;" alt=""/>
                    </div>
                    <form method="post" className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </ThemeProvider>
        </Container>
    );
}