import React  from "react";

import API from "../modules/API";
import State from "../modules/State";
import Image from "./Image";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from "@material-ui/core/Grid";

import config from '../config';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
        background: "#aa8000",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    },
    grid: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        marginBottom: "40px",
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    images: {
        [theme.breakpoints.up('sm')]: {
            margin: "0 auto",
            maxWidth: 1000
        },
        // marginBottom: "40px",
    },
    file_input: {
        position: "absolute !important",
        height: "100%",
        width: "100%",
        left: 0,
        opacity: 0,
        overflow: "hidden",
    },
    upload: {
        border: "2px dotted rgba(255,255,255,0.3);",
        color: "#fff",
        position: "relative",
        width: "calc(100% - 40px)",
        margin: "20px",
        lineHeight: "80px",
        textAlign: "center"
    },
    content: {
        position: "relative",
    },
    remove: {
        position: "absolute",
        right: 9,
        top: 7,
    },
    edit: {
        position: "absolute",
        right: 36,
        top: 7,
    },
}));

function ImageList(props)
{
    if (props.images === null || props.images.length === 0) {
        return <div></div>
    }
    const removeImage = (event) => {
        event.preventDefault();
        if (window.confirm("Weet je zeker dat je \"" + event.currentTarget.dataset.name + "\" wil verwijderen?")) {
            const api = new API(this);
            if (api.removeImage(event.currentTarget.dataset.id)) {
                props.refresh(true);
            }
        }
    }
    const editImage = (event) => {
        event.preventDefault();
        State.set("image", event.currentTarget.dataset.id);
    }
    const imageItems = props.images.map((image) =>
        <Grid key={image.id} item xs={12} sm={6} md={4} className={props.classes.grid}>
            <Card >
                <CardMedia
                    className={props.classes.media}
                    image={config.cms.endpoint + image.image}
                    title="Contemplative Reptile"
                />
                <CardContent className={props.classes.content}>
                    <IconButton size="small" aria-label="remove" className={props.classes.edit} data-id={image.id} data-name={image.name} key={image.id} onClick={editImage}>
                        <EditIcon fontSize="inherit"/>
                    </IconButton>
                    <IconButton size="small" aria-label="remove" className={props.classes.remove} data-id={image.id} data-name={image.name} key={image.name} onClick={removeImage}>
                        <DeleteIcon fontSize="inherit"/>
                    </IconButton>
                </CardContent>
            </Card>
        </Grid>
    );
    return (
        <div>
            <Image/>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                className={props.classes.container}
            >
                {imageItems}
            </Grid>
        </div>
    );
}

export default function Images(props)
{
    const api = new API(this);
    let images = props.images;
    if (!props.images) {
        images = api.images();
    }
    const classes = useStyles();
    const [refresh, setRefresh] = React.useState(false);

    const handleSubmit = (event) => {
        let response = api.uploadImage(event.target);
        if (response.errors.length === 0) {
            setRefresh(true);
        }
    }

    if (refresh) {
        images = api.images(props.type, props.id);
    }

    if (props.id === null) {
        return <div></div>;
    }

    return (
        <div>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                className={classes.grid}
            >
                    <label className={classes.upload}>
                        <input type="file" multiple accept="image/*" data-type={props.type} data-id={props.id} className={classes.file_input} onChange={handleSubmit}/>
                        Drag or click top upload images
                    </label>
            </Grid>
            <ImageList images={ images } classes={classes} refresh={setRefresh}/>
        </div>
    );
};