import React, { Component } from "react";

import Admin from './admin/Admin';

class App extends Component {
    render() {
        return (
            <Admin/>
        );
    }
}

export default App;