import React from 'react';
import {
    Route,
    NavLink,
    BrowserRouter as Router,
} from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import Projects from "./Projects";
import Project from "./Project";
import Images from "./Images";
import Home from "./Home";
import About from "./About";
import YesNo from "./YesNo";
import WhyNot from "./WhyNot";


import Tabs from "./Tabs";
import Signout from "./Signout";

// import Storage from "../modules/Storage";
import config from '../config';

import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Button from '@material-ui/core/Button';
// import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
    },
    hide: {
        display: 'none',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    icon: {
        fontSize: "18px",
        // minWidth: "40px"
    },
    logo: {
        position: "absolute"
    },
    button: {
        width: 140,
    },
    menutop: {
        textAlign: "center"
    },
    actions: {
        textAlign: "right"
    },
    offset: theme.mixins.toolbar,
}));

export default function Base()
{
    const classes = useStyles();
    // const user = Storage.get('user');

    // const theme = useTheme();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const urls = {
        home: config.admin.url,
        yes_no: config.admin.url + "yesorno",
        why_not: config.admin.url + "whynot",
        about: config.admin.url + "about",
        projects: config.admin.url + "project",
        signout: config.admin.url + "signout",
        images: config.admin.url + "images",
    }
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        main: '#a36f14',
                    },
                    secondary: {
                        main: '#ffab00',
                    },
                },
                typography: {
                    fontSize: 14
                }
            }),
        [prefersDarkMode],
    );

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.root}>
                    <Grid justify={"space-between"} container>
                        <Grid xs={1} item>
                            <img src="/img/logo-light-h.png" height="46px;" alt="" className={classes.logo}/>
                        </Grid>
                        <Grid xs={6} item>
                            <Grid container justify={"center"}>
                                <Tabs/>
                            </Grid>
                        </Grid>
                        <Grid xs={1} item  className={classes.actions}>
                            <NavLink exact to={urls.signout}>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="default"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </NavLink>
                        </Grid>
                    </Grid>
                </AppBar>
                <div className={classes.offset} />
                <Route exact path="/" component={Home}/>
                <Route exact path="/project/:id" component={Project}/>
                <Route exact path={urls.yes_no} component={YesNo}/>
                <Route exact path={urls.why_not} component={WhyNot}/>
                <Route exact path={urls.about} component={About}/>
                <Route exact path={urls.projects} component={Projects}/>
                <Route exact path={urls.images} component={Images}/>
                <Route exact path={urls.signout} component={Signout}/>
            </ThemeProvider>
        </Router>
    );
}