export default {
    app: "RIS Projecten",
    description: "RIS Projecten",
    author: "ZAPPMONKEY",
    api: {
        endpoint: "https://api.risprojecten.nl/"
        // endpoint: "http://www-dev.risprojecten.nl/"
    },
    cms: {
        endpoint: "https://cms.risprojecten.nl/"
        // endpoint: "http://www-dev.risprojecten.nl/"
    },
    admin: {
        url: "/"
    }
};
