import React from 'react';

import API from '../modules/API';
import Utils from '../modules/Utils';

import Images from './Images';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Redirect} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.secondary.dark,
        textAlign: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const api = new API(this);

export default function Project(props)
{
    const classes = useStyles();
    let [reload, setReload] = React.useState(false);
    if (reload) {
        const redirect = reload.redirect;
        // setReload(false);
        console.log(reload);
        window.location = reload.redirect;
        return (
            <Redirect
                to={{
                    pathname: redirect,
                }}
            />
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = Utils.formToObject(event.target);
        const project = api.saveProject(data);
        if (project === null) {
            alert("Het project kon niet opgeslagen worden!");
        } else {
            setReload({
                redirect: '/project/' + project.id
            })
        }
    }
    let project = {
        id: null,
        name: null,
        url: null,
        description: null,
        images: []
    };
    if (props.match.params.id && props.match.params.id !== 'add') {
        project = api.project(props.match.params.id);
    }
    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <form method="post" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        type="hidden"
                        id="id"
                        defaultValue={project.id}
                        name="id"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        defaultValue={project.name}
                        label="Naam"
                        name="name"
                        // autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="url"
                        label="Url"
                        name="url"
                        defaultValue={project.url}
                        autoComplete="url"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={10}
                        name="description"
                        defaultValue={project.description}
                        label="Omschrijving"
                        type="description"
                        id="description"
                        className={classes.submit}
                        autoComplete="current-description"
                    />
                    <Typography variant="h6" gutterBottom className={classes.subtitle}>
                        META
                    </Typography>
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_description"
                        label="Pagina omschrijving"
                        name="page_description"
                        defaultValue={project.page_description}
                        autoComplete="url"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_keywords"
                        label="Pagina keywords"
                        name="page_keywords"
                        defaultValue={project.page_keywords}
                        autoComplete="url"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                    >
                        Opslaan
                    </Button>
                </form>
            </div>
            <Images images={project.images} type={"project"} id={project.id}/>
        </Container>
    );
}