class Storage
{
    static get(key)
    {
        const item = localStorage.getItem(key);
        if (!item) {
            return null;
        }
        const parsed = JSON.parse(item);
        if (parsed.expires === -1 || parsed.expires > this.timestamp()) {
            return parsed.item;
        }
        return null;
    }

    static set(key, object, lifetime)
    {
        if (!lifetime) {
            lifetime = -1;
        }
        const item = JSON.stringify({
            expires: this.timeout(lifetime),
            item: object
        });
        localStorage.setItem(key, item);
    }

    static unset(key)
    {
        localStorage.removeItem(key);
    }

    static clear()
    {
        localStorage.clear();
    }

    static timestamp()
    {
        return Math.round((new Date()).getTime() / 1000);
    }

    static timeout(lifetime)
    {
        if (lifetime === -1) {
            return lifetime;
        }
        return this.timestamp() + lifetime;
    }
}

export default Storage;