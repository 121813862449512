class State
{
    static components = {};
    static callable = {};

    static add(key, component)
    {
        this.components[key] = component;
    }

    static set(key, state)
    {
        if (this.components.hasOwnProperty(key) && this.components[key] !== null) {
            this.components[key].setState(state);
        }
        if (this.callable.hasOwnProperty(key) && this.callable[key] !== null) {
            this.callable[key](state);
        }
    }

    static addCallable(key, callable)
    {
        if (typeof callable != 'function') {
            return;
        }
        this.callable[key] = callable;
    }

    static remove(key)
    {
        if (this.components.hasOwnProperty(key) && this.components[key] !== null) {
            this.components[key] = null;
        }
        if (this.callable.hasOwnProperty(key) && this.callable[key] !== null) {
            this.callable[key] = null;
        }
    }

    static clear()
    {
        this.components = {};
    }
}

export default State;