import Storage from "./Storage";
import config from '../config';

class API
{
    constructor(target) {
        this.target = target;
        this.endpoint = config.api.endpoint;
    }

    user()
    {
        const token = this.token();
        if (token === null) {
            return null;
        }
        const response = this.__call('GET', this.endpoint + 'user', undefined, token, false);
        if (response.code === 200) {
            Storage.set('user', response.data);
            return response.data;
        }
        Storage.unset('user');
        return null;
    }

    project(id)
    {
        return this.__get('project', id)
    }

    saveProject(project)
    {
        return this.__save('project', project)
    }

    removeProject(project_id)
    {
        return this.__delete('project', project_id)
    }

    projects()
    {
        return this.__list('project')
    }

    home()
    {
        return this.__get('home')
    }

    saveHome(home)
    {
        return this.__save('home', home)
    }

    about()
    {
        return this.__get('about')
    }

    saveAbout(about)
    {
        return this.__save('about', about)
    }

    yesNo()
    {
        return this.__get('yes_no')
    }

    saveYesNo(yesNo)
    {
        return this.__save('yes_no', yesNo)
    }

    whyNot()
    {
        return this.__get('why_not')
    }

    saveWhyNot(whyNot)
    {
        return this.__save('why_not', whyNot)
    }

    image(id)
    {
        return this.__get('image', id)
    }

    saveImage(image)
    {
        return this.__save('image', image)
    }

    removeImage(image_id)
    {
        return this.__delete('image', image_id)
    }

    uploadImage(input, callback)
    {
        const files = input.files
        const formData = new FormData()
        formData.append(input.dataset.type + '_id', input.dataset.id);
        for (let i = 0; i < files.length; i++) {
            formData.append('files[]', files[i]);
        }
        return this.__save('image/upload', formData, callback)
    }

    images(type, id)
    {
        let url = 'image';
        if (type !== undefined && id !== undefined) {
            url = 'image/' + type + '/' + id;
        }
        return this.__list(url)
    }

    __get(entity, id)
    {
        const token = this.token();
        if (token === null) {
            return null;
        }
        let key = entity;
        if (id !== undefined) {
            key = entity + '/' + id;
        }
        const response = this.__call('GET', this.endpoint + key, undefined, token, false);
        if (response.code === 200) {
            Storage.set(key, response.data);
            return response.data;
        }
        Storage.unset(key);
        return null;
    }

    __save(entity, data)
    {
        const token = this.token();
        if (token === null) {
            return null;
        }
        const key = entity + '/';
        const response = this.__call('POST', this.endpoint + key, data, token, false);
        if (response.code === 200) {
            return response.data;
        }
        return null;
    }

    __delete(entity, id)
    {
        const token = this.token();
        if (token === null) {
            return null;
        }
        const key = entity + '/' + id;
        const response = this.__call('DELETE', this.endpoint + key, {}, token, false);
        if (response.code === 200) {
            return response.data;
        }
        return null;
    }

    __list(entity)
    {
        const token = this.token();
        if (token === null) {
            return null;
        }
        const response = this.__call('GET', this.endpoint + entity, undefined, token, false);
        if (response.code === 200) {
            return response.data;
        }
        return null;
    }

    authenticate(email, password)
    {
        const response = this.__call('POST', this.endpoint + 'auth/token', {
            email: email,
            password: password
        }, undefined, false);
        if (response.code === 200) {
            Storage.set('token', response.data);
            return true;
        }
        return true;
    }

    isAuthenticated()
    {
        return (this.token() != null);
    }

    token()
    {
        const token = Storage.get('token');
        const now = this.timestamp();
        if (!token) {
            return null;
        }
        if (now < token.expires) {
            return token;
        }
        return this.refresh(token);
    }

    refresh(token)
    {
        const response = this.__call('POST', this.endpoint + 'auth/token', {
            "grant_type": "refresh_token",
            "refresh_token": token.refresh_token
        }, undefined, false);
        if (response.code === 200) {
            Storage.set('token', response.data);
            return response.data;
        }
        Storage.unset('token');
        return null;
    }

    __call(method, url, data, token, async, callback)
    {
        var request = new XMLHttpRequest();
        request.open(method, url, async);
        request.setRequestHeader('Accept', 'application/json');
        if (data instanceof FormData) {
            // request.setRequestHeader("Content-Type", "multipart/form-data; boundary=\"ris\"");
        } else {
            request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            if (data) {
                data = JSON.stringify(data)
            }
        }

        if (token) {
            request.setRequestHeader('Authorization', 'Bearer ' + token.access_token);
        }

        let obj = undefined;
        try {
            request.send(data);
            obj = JSON.parse(request.responseText);
        } catch (error) {
            console.log(error.request);
        }
        const response = {
            'code': request.status,
            'data': obj
        };
        if (typeof callback === "function") {
            callback(response);
        }
        return response;
    }

    timestamp()
    {
        return Math.round((new Date()).getTime() / 1000);
    }
}

export default API;