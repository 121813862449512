import React  from "react";
import {
    Link, NavLink,
} from "react-router-dom";

import AddIcon from '@material-ui/icons/Add';

import API from "../modules/API";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import config from '../config';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
        background: "#aa8000",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
    },
    grid: {
        flexGrow: 1,
        marginTop: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    projects: {
        [theme.breakpoints.up('sm')]: {
            margin: "0 auto",
            maxWidth: 1000
        },
    },
    title: {
        fontSize: "14px",
        color: "#fff",
        fontStyle: "normal",
        fontWeight: "bold",
    },
    content: {
        position: "relative",
    },
    remove: {
        position: "absolute",
        right: 2,
        top: 4,
    },
}));

function ProjectList(props)
{
    if (!props.projects || props.projects.length === 0) {
        return "No projects yet";
    }

    const removeProject = (event) => {
        event.preventDefault();
        if (window.confirm("Weet je zeker dat je \"" + event.currentTarget.dataset.name + "\" wil verwijderen?")) {
            const api = new API(this);
            if (api.removeProject(event.currentTarget.dataset.id)) {
                window.location.reload();
            }
        }
    }

    const projectItems = props.projects.map((project) =>
        <Grid key={project.id} item xs={12} sm={6} md={4} className={props.classes.grid}>
            <Card >
                <Link to={{
                    pathname: "/project/" + project.id
                }}>
                    <CardActionArea>
                        <CardMedia
                            className={props.classes.media}
                            image={project.images && project.images.length > 0 ? config.cms.endpoint + project.images[0].image : ""}
                            title="Contemplative Reptile"
                        />
                        <CardContent className={props.classes.content}>
                            <Typography gutterBottom variant="h5" component="h2" className={props.classes.title}>
                                {project.name}
                                <IconButton aria-label="remove" className={props.classes.remove} data-id={project.id} data-name={project.name} key={project.id} onClick={removeProject}>
                                    <DeleteIcon />
                                </IconButton>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Link>
            </Card>
        </Grid>
    );
    return (
        <div>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                className={props.classes.grid}
            >
                {projectItems}
            </Grid>
        </div>
    );
}

export default function Projects()
{
    const api = new API(this);
    const projects = api.projects();
    const classes = useStyles();

    return (
        <div>
            <NavLink exact to="/project/add">
                <Fab size="small" color="secondary" aria-label="add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </NavLink>
            <div className={classes.projects}>
                <ProjectList projects={ projects } classes={classes} />
            </div>
        </div>
    );
};