import React from 'react';

import API from '../modules/API';
import Utils from '../modules/Utils';
// import State from '../modules/State';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Redirect} from "react-router-dom";
import Images from "./Images";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.secondary.dark,
        textAlign: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const api = new API(this);

export default function About(props)
{
    const classes = useStyles();
    let [reload, setReload] = React.useState(false);
    if (reload) {
        const redirect = reload.redirect;
        setReload(false);
        return (
            <Redirect
                to={{
                    pathname: redirect,
                }}
            />
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = Utils.formToObject(event.target);
        const about = api.saveAbout(data);
        if (about === null) {
            alert("error");
        } else {
            setReload({
                redirect: '/'
            })
        }
    }
    let about = {
        id: null,
        name: null,
        url: null,
        description: null,
        images: []
    };

    about = api.about();
    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <form method="post" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        type="hidden"
                        id="id"
                        defaultValue={about.id}
                        name="id"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        defaultValue={about.title}
                        label="Titel"
                        name="title"
                        // autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={10}
                        name="description"
                        defaultValue={about.description}
                        label="Omschrijving"
                        type="description"
                        id="description"
                        className={classes.submit}
                        autoComplete="current-description"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={10}
                        name="quote"
                        defaultValue={about.quote}
                        label="Quote"
                        type="quote"
                        id="quote"
                        className={classes.submit}
                        autoComplete="current-quote"
                    />
                    <Typography variant="h6" gutterBottom className={classes.subtitle}>
                        META
                    </Typography>
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_description"
                        label="Pagina omschrijving"
                        name="page_description"
                        defaultValue={about.page_description}
                        autoComplete="url"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_keywords"
                        label="Pagina keywords"
                        name="page_keywords"
                        defaultValue={about.page_keywords}
                        autoComplete="url"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                    >
                        Opslaan
                    </Button>
                </form>
            </div>
            <Images images={about.images} type={"page"} id={about.id}/>
        </Container>
    );
}