import React from 'react';

import API from '../modules/API';
import Utils from '../modules/Utils';
// import State from '../modules/State';

import Images from './Images';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Redirect} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    divider: {
        margin: "20px 0 10px",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    subtitle: {
        color: theme.palette.secondary.dark,
        textAlign: "center",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const api = new API(this);

export default function YesNo(props)
{
    const classes = useStyles();
    let [reload, setReload] = React.useState(false);
    if (reload) {
        const redirect = reload.redirect;
        setReload(false);
        return (
            <Redirect
                to={{
                    pathname: redirect,
                }}
            />
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = Utils.formToObject(event.target);
        const yesNo = api.saveYesNo(data);
        if (yesNo === null) {
            alert("error");
        } else {
            setReload({
                redirect: '/'
            })
        }
    }

    let yesNo = api.yesNo();
    return (
        <Container component="main" maxWidth="md">
            <div className={classes.paper}>
                <form method="post" className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        type="hidden"
                        id="id"
                        defaultValue={yesNo.yes.id}
                        name="id"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        defaultValue={yesNo.yes.title}
                        label="Titel"
                        name="title"
                        // autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={10}
                        name="description"
                        defaultValue={yesNo.yes.description}
                        label="Vraag"
                        type="description"
                        id="description"
                        className={classes.submit}
                        autoComplete="current-description"
                    />
                    <Typography variant="h6" gutterBottom className={classes.subtitle}>
                        META
                    </Typography>
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_description"
                        label="Pagina omschrijving"
                        name="page_description"
                        defaultValue={yesNo.yes.page_description}
                        autoComplete="url"
                    />
                    <TextField
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="page_keywords"
                        label="Pagina keywords"
                        name="page_keywords"
                        defaultValue={yesNo.yes.page_keywords}
                        autoComplete="url"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                    >
                        Opslaan
                    </Button>
                </form>
            </div>
            <Divider light className={classes.divider}/>
            <Typography variant="h6" gutterBottom className={classes.subtitle}>
                JA
            </Typography>
            <Images images={yesNo.yes.images} type={"page"} id={yesNo.yes.id}/>
            <Divider light className={classes.divider}/>
            <Typography variant="h6" gutterBottom className={classes.subtitle}>
               NEE
            </Typography>
            <Images images={yesNo.no.images} type={"page"} id={yesNo.no.id}/>
        </Container>
    );
}