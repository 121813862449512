import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import State from "../modules/State";
import API from "../modules/API";
import Utils from "../modules/Utils";

export default function Image()
{
    const [image, setImage] = React.useState(false);

    State.addCallable('image', setImage);

    const handleClose = () => {
        setImage(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const api = new API(this);
        const data = Utils.formToObject(event.target);
        api.saveImage(data);
        setImage(false);
    }

    let data = {
        id: undefined,
        title: undefined,
        alt: undefined,
        sort: undefined,
    };
    let open = image;
    if (image) {
        const api = new API(this);
        data = api.image(image);
        open = true;
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Afbeelding #{ data.id }</DialogTitle>
                <form method="post" noValidate onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextField
                            type="hidden"
                            id="id"
                            defaultValue={data.id}
                            name="id"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Titel"
                            type="text"
                            name="title"
                            defaultValue={data.title}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="alt"
                            label="Alt tekst"
                            type="text"
                            name="alt"
                            defaultValue={data.alt}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="sort"
                            label="Volgorde"
                            type="number"
                            name="sort"
                            defaultValue={data.sort}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Annuleer
                        </Button>
                        <Button type="submit" color="primary" >
                            Opslaan
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}