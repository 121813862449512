import React, {Component} from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from "react-router-dom";

// import Home from "./Home";
// import Portfolio from "./Portfolio";

class NavTabs extends Component {

    handleCallToRouter = (event, value) => {
        this.props.history.push(value);
    }

    render () {
        let url = this.props.history.location.pathname;
        if (url.includes("/project")) {
            url = "/project";
        }
        return (
            <Tabs
                value={url}
                onChange={this.handleCallToRouter}
            >
                <Tab label="Ja / Nee" value="/yesorno">
                </Tab>
                <Tab label="Waarom niet?" value="/whynot">
                </Tab>
                <Tab label="Home" value="/">
                </Tab>
                <Tab label="Projecten" value="/project">
                </Tab>
                <Tab label="Over RIS." value="/about">
                </Tab>
                {/*<Tab label="Afbeeldingen" value="/images">*/}
                {/*</Tab>*/}
            </Tabs>
        )
    }
}

export default withRouter(NavTabs)