import React, { Component } from "react";
import Storage from "../modules/Storage";
import State from "../modules/State";
import {Redirect} from "react-router-dom";
// import config from '../config';

class Signout extends Component
{
    render()
    {
        Storage.clear();
        State.set('authenticated', false);
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        );
    }
}

export default Signout;