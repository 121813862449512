class Utils {
    static formToObject(form) {
        const data = new FormData(form);
        var object = {};
        data.forEach((value, key) => {
            // Reflect.has in favor of: object.hasOwnProperty(key)
            if(!Reflect.has(object, key)){
                object[key] = value;
                return;
            }
            if(!Array.isArray(object[key])){
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return object;
    }

    static timestamp()
    {
        return Math.round((new Date()).getTime() / 1000);
    }
}

export default Utils;